<!--
 打印设计
-->
<template>
  <div
    style="width: 100%; padding: 20px"
    width="100%"
    :visible="visible"
    destroyOnClose
    :closable="false"
    @close="visible = false"
    v-loading="divloading"
  >
    <!-- :preventActiveBehavior="!item.isActive" -->
    <a-row style="margin-bottom: 10px">
      <div style="display: inline-block; margin-bottom: 10px">
        纸张大小:&nbsp;
        <a-radio-group
          style="margin-left: 10px"
          v-model="keyType"
          button-style="solid"
          @change="changekeytype"
        >
          <a-tooltip
            placement="bottom"
            v-for="item in paperTypes"
            :key="item.key"
          >
            <template slot="title">
              <span>宽：{{ item.width }}cm</span>
              <span>&nbsp;--&nbsp;</span>
              <span>高：{{ item.height }}cm</span>
            </template>
            <a-popconfirm
              title="切换纸张大小会重置模板内容，确定切换吗?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="confirmkeyType(item.key)"
              :disabled="keyType === item.key"
            >
              <a-radio-button :value="item.key">
                {{ item.key }}
              </a-radio-button>
            </a-popconfirm>
          </a-tooltip>
          <a-popover
            title="自定义宽(cm)高(cm)"
            trigger="click"
            v-model="paperPopVisible"
          >
            <template slot="content">
              <div>
                宽：<a-input-number
                  v-model="Customize.width"
                  :min="5"
                  style="width: 70px"
                />&nbsp; 高：<a-input-number
                  v-model="Customize.height"
                  :min="5"
                  style="width: 70px"
                />
              </div>

              <div style="text-align: center; margin-top: 10px">
                <a-popconfirm
                  title="切换纸张大小会重置模板内容，确定切换吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="Customizeyes"
                >
                  <a-button type="param">确定</a-button>
                </a-popconfirm>
              </div>
            </template>
            <a-radio-button :key="'自定义'" :value="'自定义'">
              自定义
            </a-radio-button>
          </a-popover>
        </a-radio-group>
      </div>
      <a-button style="margin-left: 10px" type="primary" v-print="'#print'"
        >打印</a-button
      >
      <a-popconfirm
        style="margin-left: 10px"
        title="是否确认清空?"
        cancelText="取消"
        okText="确定清空"
        okType="danger"
        @confirm="
          printlist = [];
          form = {};
        "
      >
        <a-icon slot="icon" type="question-circle-o" style="color: red" />
        <a-button type="danger">
          清空
          <a-icon type="close" />
        </a-button>
      </a-popconfirm>
      <div
        style="display: inline-block; margin-left: 10px; margin-bottom: 10px"
      >
        选择公司：&nbsp;
        <!-- @select="changeSearchSelect" -->
        <CompanySearchSelect
          style="display: inline-block"
          v-model="CompanyId"
          @select="changeSearchSelect"
        ></CompanySearchSelect>
      </div>
      <div
        style="display: inline-block; margin-left: 10px; margin-bottom: 10px"
      >
        选择模板：
        <!--  -->
        <a-select style="width: 160px" v-model="Amodel" @change="TemNameChange">
          <a-select-option
            :value="item.Id"
            v-for="(item, index) in template"
            :key="index"
          >
            {{ item.TemName }}
          </a-select-option>
        </a-select>
      </div>
      <!-- <a-popconfirm
        style="margin-left: 10px; margin-bottom: 10px"
        title="是否确认保存模板?"
        cancelText="取消"
        okText="确定"
        @confirm="subbaocun"
      > -->
      <!--  -->
      <a-button
        style="margin-left: 10px; margin-bottom: 10px"
        type="primary"
        @click="subbaocun"
        >保存模板</a-button
      >
      <!-- </a-popconfirm> -->
    </a-row>
    <a-row :gutter="[8, 0]">
      <a-col :span="4">
        <a-card>
          <a-row
            v-for="item in list"
            :key="item.Keys"
            style="margin-bottom: 15px"
          >
            <a-button style="width: 100%" @click="addprint(item)">{{
              item.Key
            }}</a-button>
          </a-row>
        </a-card>
      </a-col>
      <a-col
        :span="15"
        :style="`overflow: hidden;
  overflow-x: auto;
  overflow-y: auto;height: 100vh;`"
        @click.self="divcalck"
      >
        <!-- @click.self="divcalck" -->
        <div
          :style="`width: ${styleW}cm;height: ${styleH}cm;border:1px solid #ccc;margin:auto`"
        >
          <!-- @click.self="divcalck" -->
          <div
            style="width: 100%; height: 100%; position: relative"
            id="print"
            @click.self="divcalck"
          >
            <vue-drag-resize
              v-for="(item, index) in printlist"
              :key="item.Id"
              snapToGrid
              :stickSize="12"
              :gridX="2"
              :gridY="2"
              :isActive="item.isActive"
              :preventActiveBehavior="item.isActive"
              :isDraggable="item.isDraggable"
              :isResizable="item.isResizable"
              :aspectRatio="item.Keys === 'qrcode' ? true : false"
              parentLimitation
              :parentW="getpx(styleW, 'w', 'px')"
              :parentH="
                getpx(
                  styleH * (Number(printPages) > 1 ? Number(printPages) : 1),
                  'h',
                  'px'
                )
              "
              :w="item.w"
              :h="item.h"
              :x="item.x"
              :y="item.y"
              :minh="28"
              :minw="80"
              @clicked="clicked(item)"
              @resizing="resizing($event, item)"
              @resizestop="resizestop($event, item)"
              @dragging="dragging($event, item)"
              @dragstop="dragstop($event, item)"
              @activated="activated(item, index)"
            >
              <div
                v-if="item.type === '1'"
                :style="`width: 100%;height: ${item.lineHeight}px;line-height: ${item.lineHeight}px;font-size: ${item.fontSize}px;font-weight:${item.bold}; flex-wrap: nowrap;`"
              >
                <p>{{ item.Key }}&nbsp;{{ item.value }}</p>
              </div>
              <div v-if="item.type === '2'" style="width: 100%; height: 100%">
                <!-- <div style="text-align: center">{{ item.Key }}</div> -->
                <vue-qr
                  v-if="item.Keys === 'qrcode'"
                  :margin="0"
                  :text="item.value"
                  :logoScale="0.2"
                  :size="item.w"
                ></vue-qr>
                <VueBarcode
                  :ref="'barcode' + item.Id"
                  v-if="item.Keys === 'barcode'"
                  :margin="0"
                  :value="item.value"
                  :height="item.height || 30"
                  :width="2"
                  elementTag="img"
                  class="barcode"
                ></VueBarcode>
              </div>
            </vue-drag-resize>
          </div>
        </div>
      </a-col>
      <a-col :span="5">
        <a-card>
          <a-form-model :model="form" v-if="form.Id">
            <div>
              <div v-if="form.type === '1'">
                <a-row>
                  <a-form-model-item :label="form.Key">
                    <a-input v-model="form.value" type="textarea" />
                  </a-form-model-item>
                </a-row>
                <a-row :gutter="15">
                  <a-col :span="12">
                    <a-form-model-item label="字体大小(px)">
                      <a-select v-model="form.fontSize" size="small">
                        <a-select-option
                          :value="item"
                          v-for="(item, index) in fontSizeList"
                          :key="index"
                        >
                          {{ item }}(px)
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item label="粗体">
                      <a-select v-model="form.bold" size="small">
                        <a-select-option
                          :value="item.value"
                          v-for="(item, index) in boldList"
                          :key="index"
                        >
                          {{ item.key }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <a-row :gutter="15">
                  <a-col :span="12">
                    <a-form-model-item label="字体行高(px)">
                      <a-select v-model="form.lineHeight" size="small">
                        <a-select-option
                          :value="item"
                          v-for="(item, index) in lineHeightList"
                          :key="index"
                        >
                          {{ item }}
                        </a-select-option>
                      </a-select>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </div>
              <div v-if="form.type === '2'">
                <a-row style="display: flex" v-if="form.Keys === 'barcode'">
                  条形码高度(px)：
                  <a-input
                    v-model="form.height"
                    type="number"
                    size="small"
                    style="flex: 1"
                  />
                </a-row>

                <el-table
                  size="mini"
                  ref="multipleTable"
                  :data="form.tableCodeData"
                  tooltip-effect="dark"
                  style="width: 100%"
                  @selection-change="handleSelectionChange($event, form)"
                  :row-key="
                    (row) => {
                      return row.Id;
                    }
                  "
                >
                  <el-table-column
                    type="selection"
                    width="50"
                    align="center"
                    :reserve-selection="true"
                  >
                  </el-table-column>
                  <el-table-column label="码段" prop="code" align="center">
                  </el-table-column>
                  <el-table-column label="顺序" align="center">
                    <template slot-scope="scope">
                      <el-select
                        v-model="scope.row.sort"
                        clearable
                        :disabled="
                          form.selectRows.findIndex(
                            (item) => item.code == scope.row.code
                          ) == -1
                        "
                      >
                        <el-option
                          v-for="item in sortList"
                          :key="item"
                          :label="item"
                          :value="item"
                          :disabled="
                            form.tableCodeData.findIndex(
                              (im) => im.sort == item
                            ) !== -1
                          "
                        ></el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <el-checkbox-group v-model="checkList">
                  <el-checkbox
                    style="display: block"
                    v-for="item in tableCodeData"
                    :key="item.key"
                    :label="item.code"
                    >{{ item.code }}</el-checkbox
                  >
                  <el-select v-model="qrcodevalue" placeholder="请选择">
                    <el-option
                      v-for="item in tableCodeData"
                      :key="item.index"
                      :label="item.index"
                      :value="item.index"
                    >
                    </el-option>
                  </el-select>
                </el-checkbox-group> -->
                <!-- <a-row :gutter="15">
                  <a-form-model-item label="商品码">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-row>
                <a-row :gutter="15">
                  <a-form-model-item label="厂商码">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-row>
                <a-row :gutter="15">
                  <a-form-model-item label="批次码">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-row>
                <a-row :gutter="15">
                  <a-form-model-item label="序号码">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-row>
                <a-row :gutter="15">
                  <a-form-model-item label="国码">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-row>
                <a-row :gutter="15">
                  <a-form-model-item label="客户商品码">
                    <a-select v-model="form.fontSize" size="small">
                      <a-select-option
                        :value="item"
                        v-for="(item, index) in fontSizeList"
                        :key="index"
                      >
                        {{ item }}(px)
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-row> -->
              </div>

              <a-row style="margin-top: 20px">
                <div
                  style="
                    width: 100%;
                    display: flex;
                    justify-content: space-around;
                  "
                >
                  <a-button @click="del(form)" type="danger">删除</a-button>
                  <a-button @click="formyes(form)" type="primary"
                    >确定</a-button
                  >
                </div>
              </a-row>
            </div>
            <!-- <a-form-model-item :label="form.Key" v-if="form.type === '3'">
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="索引排序">
                    <a-input-number v-model="form.index" :min="0">
                    </a-input-number>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="是否外部引入">
                    <a-select v-model="form.isFormTrue" size="small">
                      <a-select-option value="true"> 是 </a-select-option>
                      <a-select-option value="false"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>
              <a-row :gutter="15">
                <a-col :span="12">
                  <a-form-model-item label="新增是否可编辑">
                    <a-select v-model="form.isAddTrue" size="small">
                      <a-select-option value="true"> 是 </a-select-option>
                      <a-select-option value="false"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item label="修改是否可编辑">
                    <a-select v-model="form.isAlterTrue" size="small">
                      <a-select-option value="true"> 是 </a-select-option>
                      <a-select-option value="false"> 否 </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </a-col>
              </a-row>

              <div
                style="
                  width: 100%;
                  display: flex;
                  justify-content: space-around;
                "
              >
                <a-button @click="formyes(form)" type="primary">确定</a-button>
                <a-button @click="del(form)" type="danger">删除</a-button>
              </div>
            </a-form-model-item> -->
          </a-form-model>
        </a-card>
      </a-col>
    </a-row>
    <a-modal
      title="保存模板"
      destroyOnClose
      :visible="modalflag"
      :confirm-loading="loading"
      @ok="handleOk"
      okText="确定"
      cancelText="取消"
      @cancel="modalflag = false"
    >
      <a-form-model
        layout="horizontal"
        ref="ruleForm"
        :model="subform"
        v-bind="{
          labelCol: { span: 6 },
          wrapperCol: { span: 18 },
        }"
        :rules="rules"
      >
        <a-form-model-item label="模板类型"> 货品信息模板 </a-form-model-item>
        <a-form-model-item label="模板名称" prop="TemName">
          <a-input v-model="subform.TemName" />
        </a-form-model-item>
        <a-form-model-item label="是否覆盖模板" prop="Type">
          <a-radio-group v-model="subform.Type" button-style="solid">
            <a-radio-button :value="1"> 是 </a-radio-button>
            <a-radio-button :value="0"> 否 </a-radio-button>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item
          label="选择覆盖模板"
          v-show="subform.Type"
          :prop="subform.Type ? 'Amodel' : ''"
        >
          <a-select v-model="subform.Amodel">
            <a-select-option
              :value="item.Id"
              v-for="(item, index) in template"
              :key="index"
            >
              {{ item.TemName }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <div v-html="styleText"></div>
  </div>
</template>

<script>
import VueQr from "vue-qr";
import VueBarcode from "vue-barcode";
function jsgetDPI() {
  var arrDPI = new Array();
  if (window.screen.deviceXDPI != undefined) {
    arrDPI[0] = window.screen.deviceXDPI;
    arrDPI[1] = window.screen.deviceYDPI;
  } else {
    var tmpNode = document.createElement("DIV");
    tmpNode.style.cssText =
      "width:1in;height:1in;position:absolute;left:0px;top:0px;z-index:99;visibility:hidden";
    document.body.appendChild(tmpNode);
    arrDPI[0] = parseInt(tmpNode.offsetWidth);
    arrDPI[1] = parseInt(tmpNode.offsetHeight);
    tmpNode.parentNode.removeChild(tmpNode);
  }
  return arrDPI;
}
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  data() {
    return {
      divloading: false,
      CompanyId: "",

      fontSizeList: [12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34],
      boldList: [
        { key: "默认", value: "initial" },
        { key: "加粗", value: "bold" },
        { key: "100", value: "100" },
        { key: "200", value: "200" },
        { key: "300", value: "300" },
        { key: "400", value: "400" },
        { key: "500", value: "500" },
        { key: "600", value: "600" },
        { key: "700", value: "700" },
        { key: "800", value: "800" },
        { key: "900", value: "900" },
      ],
      alignList: [
        { key: "左对齐", value: "left" },
        { key: "居中", value: "center" },
        { key: "右对齐", value: "right" },
      ],
      lineHeightList: [26, 28, 30, 32, 34, 36, 38, 40, 42, 44, 46],
      subform: {
        Type: 0,
        TemName: "",
        Amodel: "",
      },
      modalflag: false,
      loading: false,
      form: {},
      printlist: [],
      keyType: "11cm",
      printPages: 1,
      printsize: "portrait",
      Customize: {
        key: "自定义",
        width: 8,
        height: 8,
      },

      paperTypes: [
        {
          key: "8cm",
          width: 8,
          height: 8,
        },
        {
          key: "11cm",
          width: 11,
          height: 8,
        },
        {
          key: "16cm",
          width: 16,
          height: 8,
        },
      ],

      visible: true,

      list: [
        {
          Key: "货品编号",
          Keys: "cInvCode",
          value: "",
          Id: "cInvCode",
          type: "1",
          w: 250,
          h: 35,
        },
        {
          Key: "货品名称",
          Keys: "cInvName",
          value: "",
          Id: "cInvName",
          type: "1",
          w: 250,
          h: 35,
        },
        {
          Key: "批次",
          Keys: "Batch",
          value: "",
          Id: "Batch",
          type: "1",
          w: 250,
          h: 35,
        },
        {
          Key: "公司名称",
          Keys: "CompanyName",
          value: "",
          Id: "CompanyName",
          type: "1",
          w: 250,
          h: 35,
        },
        {
          Key: "日期",
          Keys: "Time",
          value: "",
          Id: "Time",
          type: "1",
          w: 250,
          h: 35,
        },
        // {
        //   Key: '国际二维码',
        //   Keys: 'gjqrcode',
        //   value: '694',
        //   Id: 'gjqrcode',
        //   type: '2',
        //   w: 80,
        //   h: 80,
        // },
        // {
        //   Key: '平台二维码',
        //   Keys: 'gsqrcode',
        //   value: '123',
        //   Id: 'gsqrcode',
        //   type: '2',
        //   w: 80,
        //   h: 80,
        // },
        // {
        //   Key: '客户二维码',
        //   Keys: 'khqrcode',
        //   value: '123',
        //   Id: 'khqrcode',
        //   type: '2',
        //   w: 80,
        //   h: 80,
        // },
        {
          Key: "二维码",
          Keys: "qrcode",
          value: "123",
          Id: "qrcode",
          type: "2",
          w: 80,
          h: 80,
        },
        {
          Key: "条形码",
          Keys: "barcode",
          value: "000",
          Id: "barcode",
          type: "2",
          w: 150,
          h: 50,
        },
      ],
      qrcodevalue: "",
      checkList: [],
      sortList: [1, 2, 3, 4, 5],
      selectRows: [],
      tableCodeData: [
        { code: "商品码", sort: "", index: 1, Id: "1" },
        // { code: '厂商码', sort: '', index: 2, Id: '2' },
        { code: "批次码", sort: "", index: 2, Id: "2" },
        { code: "序号码", sort: "", index: 3, Id: "3" },
        { code: "国码", sort: "", index: 4, Id: "4" },
        { code: "客户商品码", sort: "", index: 5, Id: "5" },
      ],
      template: [],
      typeitem: {
        isActive: false, //激活状态
        isDraggable: true,
        isResizable: true,
        aspectRatio: true, //允许等比例缩放
        parentLimitation: true, //不允许超出父级元素
        parentW: 950,
        parentH: 1386,
        // w: 260,
        // h: 28,
        x: 0,
        y: 0,
      },
      type: "",
      parentW: null,
      parentH: null,
      styleW: 11,
      styleH: 8,
      paperPopVisible: false,
      rules: {
        TemName: [
          { required: true, message: "请输入模板名称", trigger: "blur" },
        ],
        Type: [
          { required: true, message: "请选择是否覆盖模板", trigger: "change" },
        ],
        Amodel: [
          {
            required: true,
            message: "请选择需要覆盖的模板",
            trigger: "change",
          },
        ],
      },
      Amodel: "",
      thead: [
        {
          name: "名称",
          key: "name",
        },
      ],
      headlist: {
        name: "名称",
        key: "name",
      },
      theadmodel: "",
      Amodel2: "",
      datlist: [
        {
          key: "name",
          value: "恩恩额",
        },
      ],
      trueenflase: [
        { keys: "是", value: true },
        { keys: "否", value: false },
      ],
      formobj: {},
      addtablelie: "",
      deletelieList: [],
      tabletabs: "列设置",
      tablerowtabs: "行设置",
      styleText:
        '<style type="text/css" media="print">\n' +
        "  @page { size: portrait; }\n" +
        "</style>",
    };
  },
  components: {
    VueQr,
    VueBarcode,
    CompanySearchSelect,
  },
  created() {
    // this.getCompanyIdList()
    // this.getbutlist()
    // this.getGetQrCodeTmpList()
  },
  methods: {
    confirmkeyType(key) {
      this.keyType = key;
      this.changekeytype();
    },
    getGetQrCodeTmpList(id) {
      this.$http
        .post("/Base/Base_Template/GetQrCodeList?CompanyId=" + id, {})
        .then((res) => {
          this.template = res.Data;
        });
    },
    handleSelectionChange(val, form) {
      form.selectRows = val;
    },
    changeSearchSelect(a) {
      // //console.log(a)

      this.template = [];
      this.Amodel = "";
      this.getGetQrCodeTmpList(a.Id);

      // this.loading = true
      // this.$http
      //   .post(`/Base/Base_Template/GetCompanyTemp?CompayId=${a.Id}&type=1`, {})
      //   .then((res) => {
      //     if (res.Success) {
      //       this.printlist = []
      //       setTimeout(() => {
      //         var obj = JSON.parse(res.Data.Content)
      //         this.keyType = obj.keyType
      //         this.printPages = obj.printPages
      //         this.printsize = obj.printsize
      //         this.styleH = obj.styleH
      //         this.styleW = obj.styleW
      //         this.printlist = obj.printlist
      //       }, 1)
      //     } else {
      //       this.$message.warning(res.Msg)
      //     }
      //     this.loading = false
      //   })
    },
    dleetlieyes(form) {
      if (this.deletelieList.length > 0) {
        // var arr = form.columns.filter(item => {
        //   return (
        //     item.dataIndex !=
        //     (this.deletelieList
        //       .map(val => {
        //         return val.dataIndex
        //       })
        //       .indexOf(item.dataIndex) ==
        //       -1)
        //   )
        // })
        var arr = form.columns.filter(
          (item) => !this.deletelieList.includes(item.dataIndex)
        );

        // //console.log(arr)
        form.columns = arr;
        var index = this.printlist.findIndex((item) => {
          return item.Id === form.Id;
        });
        this.printlist[index] = form;
        this.form = {};
        this.formobj = {};
        this.deletelieList = [];
      } else {
        this.$message.error("请选择后删除");
      }
    },
    addtablelieyes(form) {
      if (this.addtablelie) {
        form.columns.push({
          title: this.addtablelie,
          dataIndex: new Date().getTime() + "" + this.addtablelie,
        });
        var index = this.printlist.findIndex((item) => {
          return item.Id === form.Id;
        });
        this.printlist[index] = form;
        this.form = {};
        this.formobj = {};
        this.addtablelie = "";
      } else {
        this.$message.error("请输入名称后确定");
      }
    },
    theadchange(a) {
      //console.log(a)
      var obj = this.form.columns.find((item) => {
        return item.dataIndex === a;
      });
      //console.log(obj)
      //console.log(this.form)
      this.formobj = { ...obj };
    },
    addcol() {
      this.thead.push({
        name: "名称" + this.thead.length,
        key: "name" + new Date().getTime() + "-" + this.thead.length,
      });
      // this.datlist.push({
      //   key: 'name' + this.thead.length,
      //   value: '恩恩额' + this.thead.length
      // })
    },
    fontSizeChange() {},
    printPageschange() {},
    getbutlist() {
      this.$http.post("/Base/Base_ControL/GetDataList", {}).then((res) => {
        res.Data.forEach((item) => {
          this.$set(item, "lineHeight", 28);
          this.$set(item, "fontSize", "18");
          this.$set(item, "bold", "initial");
          this.$set(item, "align", "center");
        });
        this.list = res.Data;
      });
      this.$http.post("/Base/Base_Template/GetDataList", {}).then((res) => {
        if (res.Success) {
          this.template = res.Data;
        } else {
          this.template = [];
        }
      });
      // axios({
      //   url: this.$baseUrl + '/Base/Base_ControL/GetDataList',
      //   method: 'post',
      //   data: {},
      // }).then((res) => {
      //   //console.log(res)
      //   res.data.Data.forEach((item) => {
      //     this.$set(item, 'lineHeight', 28)
      //     this.$set(item, 'fontSize', '18')
      //     this.$set(item, 'bold', 'initial')
      //     this.$set(item, 'align', 'center')
      //   })
      //   this.list = res.data.Data
      // })
      // axios({
      //   url: this.$baseUrl + '/Base/Base_Template/GetDataList',
      //   method: 'post',
      //   data: {},
      // }).then((res) => {
      //   if (res.data.Success) {
      //     this.template = res.data.Data
      //   } else {
      //     this.template = []
      //   }
      //   //console.log(res)
      // })
    },
    gethuoqu() {
      //console.log()
      var obj = JSON.parse(localStorage.getItem("模板"));
      this.keyType = obj.keyType;
      this.printsize = obj.printsize;
      this.styleH = obj.styleH;
      this.styleW = obj.styleW;
      this.printlist = obj.printlist;
    },
    TemNameChange(a) {
      //console.log(a)
      var str = this.template.find((item) => {
        return item.Id === a;
      });
      this.printlist = [];

      this.printlist = [];

      setTimeout(() => {
        var obj = JSON.parse(str.Content);
        this.keyType = obj.keyType;
        this.printPages = obj.printPages;
        this.printsize = obj.printsize;
        this.styleH = obj.styleH;
        this.styleW = obj.styleW;
        this.printlist = obj.printlist;
        this.$set(this.subform, "TemName", str.TemName);
      }, 1);
      //console.log(JSON.parse(str.Content))
    },
    getCompanyIdList() {
      this.divloading = true;
      this.$http
        .post("/Base_Manage/Base_Company/GetDataList", {
          PageIndex: 1,
          PageRows: 9999,
          SortField: "Id",
          SortType: "desc",
          Search: {
            condition: "CreatorId",
            keyword: JSON.parse(sessionStorage.getItem("userInfo")).Id,
          },
        })
        .then((resJson) => {
          if (resJson.Success) {
            this.CompanyId = resJson.Data[0].Id;
            this.changeSearchSelect(resJson.Data[0]);
          }
          this.divloading = false;
        });
    },
    handleOk() {
      //console.log(this.CompanyId)
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          return;
        }
        var obj = {
          // e==='自定义'?
          printPages: this.printPages, //打印张数
          keyType: this.keyType, //纸张类型
          styleW: this.styleW, //纸张宽度厘米
          styleH: this.styleH, ////纸张高度厘米
          printsize: this.printsize, //打印类型//横向 纵向
          printlist: this.printlist, //打印内容
        };
        //console.log(obj, 'obj')
        this.loading = true;
        this.$http
          .post(
            `/Base/Base_Template/SaveTmpQrCode?Type=${
              this.Amodel ? 1 : 0
            }&TmpId=${this.subform.Amodel}`,
            {
              CompanyId: this.CompanyId,
              TemName: this.subform.TemName,
              Content: JSON.stringify(obj),
              TemType: 1,
            }
          )
          .then((res) => {
            this.loading = false;
            if (res.Success) {
              this.$message.success("保存成功");

              this.modalflag = false;
              this.getGetQrCodeTmpList(this.CompanyId);
            } else {
              this.$message.error("保存失败");
            }
          });
      });
    },
    subbaocun() {
      if (!this.CompanyId) {
        return this.$message.warning("请选择公司后保存");
      }
      //console.log(this.printlist)
      if (this.printlist.length === 0) {
        return this.$message.warning("请添加后保存");
      }
      var arr = this.printlist.filter((item) => {
        return item.Keys === "qrcode" || item.Keys === "barcode";
      });
      var flag = arr.some((item) => {
        return item.selectRows.length == 0;
      });
      if (flag) {
        return this.$message.warning("模板内二维码或条形码未设置码段，请检查");
      }
      //console.log(flag)
      //console.log(arr)
      this.modalflag = true;
      this.$set(this.subform, "Type", 0);
      this.$set(this.subform, "Amodel", this.Amodel);
      // this.subform = {
      //   Type: 0,
      //   TemName:"",
      //   Amodel: "",
      // };
    },
    printsizechange() {
      var h = this.styleH;
      var w = this.styleW;
      //console.log(w, h)

      if (this.printsize === "portrait") {
        if (h > w) {
          this.styleW = w;
          this.styleH = h;
        } else {
          this.styleW = h;
          this.styleH = w;
        }
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: portrait; }\n" +
          "</style>";
      } else {
        if (h > w) {
          this.styleW = h;
          this.styleH = w;
        } else {
          this.styleW = w;
          this.styleH = h;
        }
        this.styleText =
          '<style type="text/css" media="print">\n' +
          "  @page { size: landscape; }\n" +
          "</style>";
      }
      this.printlist = [];
      this.form = {};
      this.Amodel = "";
      //console.log(this.styleH, 'styleH')
      //console.log(this.styleW, 'styleW')
    },
    Customizeyes() {
      this.keyType = this.Customize.key;
      this.styleW = this.Customize.width;
      this.styleH = this.Customize.height;
      this.paperPopVisible = false;
      this.printlist = [];
    },
    changekeytype() {
      var obj = this.paperTypes.find((item) => {
        return item.key === this.keyType;
      });
      this.styleW = obj.width;
      this.styleH = obj.height;
      this.printsize = "portrait";
      this.styleText =
        '<style type="text/css" media="print">\n' +
        "  @page { size: portrait; }\n" +
        "</style>";
      this.printlist = [];
    },
    getpx(num, wh, str) {
      var dpi = jsgetDPI();
      if (str == "px") {
        if (wh == "w") {
          return (num / 2.54) * dpi[0];
        }
        if (wh == "h") {
          return (num / 2.54) * dpi[1];
        }
      }
      if (str == "cm") {
        if (wh == "w") {
          return (num / dpi[0]) * 2.54;
        }
        if (wh == "h") {
          return (num / dpi[1]) * 2.54;
        }
      }
      // px转cm：cm = pxValue/dpi*2.54
      //           px = cm/2.54*dpi
    },
    // print() {
    //   var dpi = jsgetDPI()
    //   //console.log(dpi)
    //   //console.log((21 / 2.54) * dpi[0])
    //   // pluc.px2cm(100)
    //   printct({
    //     printable: 'print', //指定打印区域
    //     type: 'html', //type:可以是 html 、pdf、 json 等
    //     targetStyles: ['*'], // 这样设置继承了页面要打印元素原有的css属性
    //     //传入自定义样式的字符串，使用在要打印的html页面 也就是纸上的样子。
    //     /*portrait： 纵向打印,  landscape: 横向*/
    //     style: `@page{size: ${this.printsize};}`
    //   })
    // },
    preview(info) {
      //console.log(info)
      this.form.value = info.url || info.response.url;
    },
    del(form) {
      //console.log(form)

      this.printlist.forEach((item) => {
        this.$set(item, "isActive", false);
      });
      setTimeout(() => {
        var index = this.printlist.findIndex((item) => {
          return item.Id === this.form.Id;
        });
        //console.log(index, 'index')

        this.form = {};
        this.printlist.splice(index, 1);
      }, 100);
    },
    formyes(form) {
      //console.log(form)
      if (form.type === "2") {
        if (form.selectRows.length == 0)
          return this.$message.error("请至少勾选一项内容");
        // 排序验证
        if (!form.selectRows.every((item) => item.sort > 0))
          return this.$message.error("请选择排序");
      }
      if (form.Keys === "barcode") {
        form.value = "";
        form.selectRows.forEach((item) => {
          form.value = "000" + form.value;
          //console.log(form.value)
        });
        // var index = this.printlist.findIndex((item) => {
        //   return item.Id === form.Id
        // })
        // this.printlist.splice(index, 1)
        // form.isActive = false
        // // form.w = 'auto'
        // // form.h = 'auto'
        // this.printlist.push({ ...form })
        // this.$nextTick(() => {
        //   //console.log(this.$refs['barcode' + form.Id])
        // })
        // this.form = {}
        // this.formobj = {}
        // return
        //先删除再添加
      }
      //console.log(form, 'form')
      var index = this.printlist.findIndex((item) => {
        return item.Id === form.Id;
      });
      this.printlist[index] = form;
      this.printlist[index].isActive = false;
      //console.log(this.printlist[index], '/*/*/*')
      this.form = {};
      this.formobj = {};
    },
    handleChange(info) {
      //console.log(info)
      if (info.file.status === "done") {
        var urllist = info.fileList
          .filter((x) => x.status == "done")
          .map((x) => x.url || x.response.url);
        this.form.value = urllist[0];
      } else if (info.file.status === "error") {
        this.$message.error(`${info.file.name} 上传失败.`);
      }
    },
    addprint(item) {
      var arr = [];
      var assobj = {};
      this.printlist.forEach((val) => {
        this.$set(val, "isActive", false);
      });
      // var index = this.printlist.findIndex((val) => {
      //   return item.Id === val.Id
      // })
      // if (index !== -1) {
      //   this.printlist[index].isActive = true
      //   this.form = { ...this.printlist[index] }
      //   return
      // }
      this.printlist.forEach((val) => {
        if (val.Key === item.Key) {
          arr.push(val);
        }
      });
      this.$set(item, "lineHeight", 28);
      this.$set(item, "fontSize", "18");
      this.$set(item, "bold", "initial");
      this.$set(item, "align", "center");
      if (item.type === "2") {
        this.$set(item, "selectRows", []);
        this.$set(
          item,
          "tableCodeData",
          JSON.parse(JSON.stringify(this.tableCodeData))
        );
      }
      this.form = {};
      if (arr.length > 0) {
        //console.log('true')
        var assobj2 = { ...item };
        this.$set(assobj2, "Id", item.Id + "-" + arr.length);
        Object.assign(assobj2, { ...this.typeitem }, { ...assobj2 });
        // this.$set(assobj2, 'isActive', false)
        this.printlist = [...this.printlist, assobj2];
        // this.form = JSON.parse(JSON.stringify(assobj2))
      } else {
        Object.assign(assobj, { ...this.typeitem }, { ...item });
        // this.$set(assobj, 'isActive', false)
        this.printlist = [...this.printlist, assobj];
        // this.form = JSON.parse(JSON.stringify(assobj))
      }
    },
    divcalck() {
      this.printlist.forEach((item) => {
        this.$set(item, "isActive", false);
      });
      this.form = {};
    },
    activated(item, index) {
      //console.log('组件外')
    },
    dragstop(a, item) {
      setTimeout(() => {
        item.w = a.width;
        item.h = a.height;
        item.x = a.left;
        item.y = a.top;
        // //console.log(item)

        this.form = { ...item };
        if (this.form.type === "2") {
          if (this.form.selectRows.length > 0) {
            this.$nextTick(() => {
              //console.log('11111111111')
              var arr = [];
              this.form.tableCodeData.forEach((item) => {
                this.form.selectRows.forEach((val) => {
                  if (item.Id === val.Id) {
                    arr.push(item);
                  }
                });
              });
              //console.log(arr)
              arr.forEach((item) => {
                this.$refs.multipleTable.toggleRowSelection(item);
              });
            });
          }
        }
      }, 1);
      //console.log(a, '拖拽结束')
    },
    dragging(a, item) {
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
      // this.form = {}
      // //console.log(a)
    },
    resizestop(a, item) {
      // item.isActive = false
      // item.aspectRatio = false
      // item.aspectRatio = true
      item.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
      // this.form = {}
      this.form = { ...item };
      //console.log(a, '缩放结束')
      // this.form = {}
      // this.form = { ...item }
      // if (this.form.type === '2') {
      //   // this.$nextTick(() => {
      //   //   this.$refs.multipleTable.clearSelection()
      //   // })
      //   if (this.form.selectRows.length > 0) {
      //     this.$nextTick(() => {
      //       this.form.selectRows.forEach((item) => {
      //         //console.log(item)
      //         this.$refs.multipleTable.toggleRowSelection(item)
      //       })
      //     })
      //   }
      // }
    },
    resizing(a, item) {
      tem.w = a.width;
      item.h = a.height;
      item.x = a.left;
      item.y = a.top;
    },
    clicked(item) {
      // //console.log(item, '点击')
      this.form = {};

      this.printlist.forEach((val) => {
        this.$set(val, "isActive", false);
      });
      this.$set(item, "isActive", true);
    },
    tablecallback(a) {
      //console.log(a)
    },
    refprint() {
      //console.log(this.$refs.print.innerHTML)
      localStorage.setItem("print", this.$refs.print.innerHTML);
    },
    returnclick() {
      this.$router.go(-1);
    },
    open() {
      this.visible = true;
      this.getbutlist();
      this.printlist = [];
      this.form = {};
      this.Amodel = "";
      // this.htmlval = localStorage.getItem('print')
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .ant-form-item {
  margin-bottom: 1px;
}
@page {
  size: auto;
  margin: 0mm !important;
}
.barcode {
  width: 100%;
  height: 100%;
}
/deep/ .vue-barcode-element {
  width: 100%;
  height: 100%;
}
// /deep/ .card-design {
//   padding: 0;
//   overflow: hidden;
//   overflow-x: auto;
//   overflow-y: auto;
// }
// .logos {
//   padding: 6px 24px;
//   display: flex;
//   justify-content: center;
//   align-self: center;

//   img {
//     height: 40px;
//     width: 40px;
//   }
// }

// .menus {
//   padding: 10px 24px;
// }

// // hiprint 拖拽图片
// .hiprint-printElement-image-content {
//   img {
//     content: url('~@/assets/logo.png');
//   }
// }
// // 修改 页眉/页脚线 样式
// .hiprint-headerLine,
// .hiprint-footerLine {
//   border-color: red !important;
// }

// .hiprint-headerLine:hover,
// .hiprint-footerLine:hover {
//   border-top: 3px dashed red !important;
// }

// .hiprint-headerLine:hover:before {
//   content: '页眉线';
//   left: calc(50% - 18px);
//   position: relative;
//   background: #ffff;
//   top: -12px;
//   color: red;
//   font-size: 12px;
// }

// .hiprint-footerLine:hover:before {
//   content: '页脚线';
//   left: calc(50% - 18px);
//   position: relative;
//   color: red;
//   background: #ffff;
//   top: -12px;
//   font-size: 12px;
// }
// @page {
//   // 只显示页码，根据实际内容调整，可根据浏览器打印预览设置边距设置修改选项查看
//   // margin:  5mm 13mm 0 4mm!important;
//   size: landscape; /*portrait： 纵向打印,  landscape: 横向*/

//   // orphans属性指定必须留在页面底部的段落的最小行数。
//   // widows属性指定必须留在页面顶部的段落的最小行数。
//   // 这是在每页底部创建 4 行和在顶部创建 3 行的示例 -
//   // orphans:4; widows:3;
// }
</style>
